import { BUYER_PUBLIC_KEYS, STARTUP_INTRO_KEYS } from "../constants";
import { IBuyer, IStartup } from "../models";
import { getObjFields } from "../utils";
import { getFillingPercent } from "../utils/formUtils";

export const FillingPercentsService = {
  BuyerInfo: (buyer: IBuyer) => {
    const excludedFields: Array<keyof IBuyer> = [
      "phone_number",
      "image",
      "active_subscription",
      "title",
    ];
    return getFillingPercent(buyer, [...excludedFields, "bio"]);
  },
  BuyerPrivateInfo: (buyer: IBuyer) => {
    const excludedFields: Array<keyof IBuyer> = [
      ...BUYER_PUBLIC_KEYS,
      "phone_number",
      "image",
      "active_subscription",
      "title",
    ];
    return getFillingPercent(buyer, [...excludedFields, "bio"]);
  },
  StartupPublicInfo: (startup: IStartup) => {
    const excludeFields: Array<keyof IStartup> = [
      ...STARTUP_INTRO_KEYS,
      "finished_at",
      "files",
      "chat_auto_approve",
    ];

    if (!startup.highlights?.other) {
      excludeFields.push("custom_highlights");
    }
    if (!startup.key_assets?.other) {
      excludeFields.push("keywords");
    }

    return getFillingPercent(startup, excludeFields);
  },
  StartupPrivateInfo: (startup?: IStartup) => {
    if (!startup) {
      return 100;
    }
    return getFillingPercent(getObjFields(startup, STARTUP_INTRO_KEYS));
  },
};
