import { ApiService } from "./ApiService";
import { IPaginatedReq, IStartup, IStartupType } from "../models";
import { UserService } from "./UserService";
import { AcquisitionType, StartupSorting } from "../types";
import { mapList, mapStartupIn, mapStartupOut } from "../models/mappers";
import { IApiStartup } from "../models/api";

export interface IStartupsParams extends IPaginatedReq {
  ordering?: StartupSorting;
  age_min?: number;
  age_max?: number;
  customers?: number;
  profit_min?: number;
  profit_max?: number;
  revenue_min?: number;
  revenue_max?: number;
  price_min?: number;
  price_max?: number;
  hide_under_offer?: number;
  search?: string;
  type?: number[];
  is_validated?: boolean;
  acquisition_type?: AcquisitionType;
}

export const StartupService = {
  GetSellerStartup: async (sellerID: number): Promise<IStartup | undefined> => {
    const seller = await UserService.GetSeller(sellerID);
    const [startupID] = seller.startups;
    return startupID ? await StartupService.GetStartup(startupID) : undefined;
  },
  GetStartup: (id: number | string): Promise<IStartup> => {
    return ApiService.Get(`/startup/${id}/`).then(mapStartupIn);
  },
  PatchStartup: ({ id, values }: { id: number; values: Partial<IStartup> }) => {
    return ApiService.Patch(`/startup/${id}/`, mapStartupOut(values)).then(
      mapStartupIn
    );
  },
  DeleteStartup: (id: number | string) => {
    return ApiService.Delete(`/startup/${id}/`);
  },
  GetStartups: (params: IStartupsParams) => {
    return ApiService.GetPaginatedData<IApiStartup>(`/startup/`, params).then(
      (v) => mapList<IApiStartup, IStartup>(v, mapStartupIn)
    );
  },
  GetTypes: () => {
    return ApiService.GetPaginatedData<IStartupType>("/startup-types/", {
      page_size: 100,
    }).then((v) =>
      v.results.sort((a, b) => (a.order ?? 100) - (b.order ?? 100))
    );
  },
  DraftStartup: (id: number | string) => {
    return ApiService.Patch(`/startup/${id}/draft/`, {});
  },
  PublishStartup: (id: number | string) => {
    return ApiService.Patch(`/startup/${id}/publish/`, {});
  },
};
