import { t } from "i18next";
import { Helmet } from "react-helmet";

interface AppHelmetProps {
  title?: string;
  description?: string;
}

export const AppHelmet = ({ title, description }: AppHelmetProps) => {
  return (
    <Helmet
      defaultTitle={t("meta.title")}
      titleTemplate={`%s | ${t("meta.title")}`}
    >
      {title && <title>{title}</title>}
      <meta name="description" content={description || t("meta.description")} />
    </Helmet>
  );
};
