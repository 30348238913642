import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { CommonPath } from "../../types";
import { useAppContext } from "../../context/AppContext";
import { ClientPath } from "../../../client/types";
import { AdminPath } from "../../../admin/types";

export enum RouteType {
  Authed,
  NotAuthed,
  Client,
  Advisor,
  Admin,
}

interface RestrictedRouteProps {
  type?: RouteType;
  target: React.FC<any>;
}

export const RestrictedPath = ({
  type,
  target: Target,
}: RestrictedRouteProps) => {
  const location = useLocation();
  const { isAuthed, user } = useAppContext();
  const isAdmin = user.is_staff;

  if (type === RouteType.Authed && !isAuthed) {
    return (
      <Navigate
        to={{
          pathname: CommonPath.SignIn,
        }}
        state={{ from: location.pathname }}
        replace={true}
      />
    );
  }

  if (isAuthed) {
    if (type === RouteType.NotAuthed) {
      return (
        <Navigate
          to={{
            pathname: ClientPath.Home,
          }}
          replace={true}
        />
      );
    }
    if (type === RouteType.Client) {
      if (!user.is_email_verified) {
        return (
          <Navigate
            to={{
              pathname: CommonPath.Verification,
            }}
            replace={true}
          />
        );
      }
      if (isAdmin) {
        return (
          <Navigate
            to={{
              pathname: AdminPath.Users,
            }}
            replace={true}
          />
        );
      }
      if (!user.infoSettled) {
        return (
          <Navigate
            to={{
              pathname: ClientPath.Introduction,
            }}
            replace={true}
          />
        );
      }
    }
    if (type === RouteType.Admin) {
      if (!user.is_email_verified) {
        return (
          <Navigate
            to={{
              pathname: CommonPath.Verification,
            }}
            replace={true}
          />
        );
      }
      if (!isAdmin) {
        return (
          <Navigate
            to={{
              pathname: ClientPath.Home,
            }}
            replace={true}
          />
        );
      }
    }
  }

  return <Target />;
};
