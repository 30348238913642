import { IFile, IPaginatedRes, IStartup } from "./index";
import {
  boolRecToStr,
  strToBoolRec,
  strToValueObjArr,
  valueObjToStr,
} from "../utils/formUtils";
import moment from "moment";
import { IApiStartup } from "./api";
import { getAge } from "../utils";

export const mapList = <I, O>(
  origin: IPaginatedRes<I>,
  mapper: (v: I) => O
): IPaginatedRes<O> => ({
  count: origin.count,
  results: origin.results.map(mapper),
});

export const mapStartupIn = (origin: IApiStartup): IStartup => {
  const date_founded = origin.date_founded
    ? new Date(origin.date_founded)
    : null;
  return {
    ...origin,
    highlights: strToBoolRec(origin.highlights),
    custom_highlights: strToValueObjArr(origin.custom_highlights).length
      ? strToValueObjArr(origin.custom_highlights)
      : [{ value: "" }],
    key_assets: strToBoolRec(origin.key_assets),
    date_founded,
    age: date_founded ? getAge(date_founded) : null,
  };
};

export const mapStartupOut = ({ age, ...origin }: Partial<IStartup>): any => {
  return {
    ...origin,
    ...(origin.highlights && { highlights: boolRecToStr(origin.highlights) }),
    ...(origin.custom_highlights && {
      custom_highlights: valueObjToStr(origin.custom_highlights),
    }),
    ...(origin.key_assets && { key_assets: boolRecToStr(origin.key_assets) }),
    ...(origin.date_founded && {
      date_founded: moment(origin.date_founded).format("YYYY-MM-DD"),
    }),
    ...(origin.files && { files: origin.files.map((file: IFile) => file?.id) }),
  };
};
