import { NUMBER_ABBREVIATIONS, PRESENT_DATE } from "../constants";

export const getLast = <T extends any>(arr: T[]): T => {
  return arr[arr.length - 1];
};

export const getObjFields = <T extends unknown>(
  obj: T,
  fields: Array<keyof T>
): Partial<T> => {
  return fields.reduce((acc, item) => {
    acc[item] = obj[item];
    return acc;
  }, {} as Partial<T>);
};

export const supportsEmoji = (emoji: string): boolean => {
  const ctx = document.createElement("canvas").getContext("2d");
  if (!ctx) return false;
  ctx.canvas.width = ctx.canvas.height = 1;
  ctx.fillText(emoji, -4, 4);
  // Not a transparent pixel
  return ctx.getImageData(0, 0, 1, 1).data[3] > 0;
};

export const getAge = (from: Date, to: Date = PRESENT_DATE) => {
  if (from > to) return 0;
  const diff = +to - +from;
  // miliseconds from epoch
  const ageDate = new Date(diff);
  return Math.abs(ageDate.getUTCFullYear() - 1970) || 1;
};

export const showValue = (
  value: any = undefined,
  {
    placeholder = "-",
    prefix,
    suffix,
  }: {
    prefix?: string;
    suffix?: string;
    placeholder?: string;
  } = {}
) => {
  if (!value) return placeholder;
  const lvalue = Array.isArray(value) ? value.join(", ") : value;
  return `${prefix ? `${prefix} ` : ""}${lvalue}${suffix ? ` ${suffix}` : ""}`;
};

export function abbreviateNumber(
  num: any,
  digit?: number
): string | undefined {
  if (!num || isNaN(num)) return;

  const sign = Math.sign(num) >= 0;
  const defaultValue = num.toString();

  num = Math.round(Math.abs(num));

  const tier = Math.log10(num) / 3 | 0;
  if (tier === 0) return defaultValue;

  const suffix = NUMBER_ABBREVIATIONS[tier];
  if (!suffix) return defaultValue;

  const scale = Math.pow(10, tier * 3);
  const scaled = num / scale;

  let value: number = scaled;

  if (suffix && suffix === NUMBER_ABBREVIATIONS[1]) {
    value = +value.toFixed(1);
  }

  if (suffix && suffix !== NUMBER_ABBREVIATIONS[0]) {
    value = +value.toFixed(3);
  }

  if (!isNaN(digit as number)) {
    value = +value.toFixed(digit)
  }

  return (!sign ? "-" : "") + value + suffix;
}

export const showCurrency = (
  v: string | number | null | undefined,
  currency: string = "$"
) => showValue(v, { prefix: currency });

export const isURL = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const getFlagEmoji = (countryCode: string): string => {
  const codePoints = countryCode
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};
