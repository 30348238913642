import { createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    main: "#0000FF",
    light: "#FF00DC",
  },
  secondary: {
    main: "#00004B",
    dark: "#232321",
  },
  info: {
    main: "#7A7A7A",
    light: "#F0F0F0",
    dark: "#B8B8B8",
    lightest: "#FBFBFB",
  },
  gradients: {
    title: "linear-gradient(90deg, #0000FF 0%, #8B00C7  103.07%)",
    card: "linear-gradient(90deg, rgba(0,0,255,1) 0%, rgba(0,255,209,1) 50%, rgba(255,0,220,1) 100%)",
    dark: "linear-gradient(90deg, #21C9DC 0%, #0000FF 103.07%)",
    turqoise: "linear-gradient(90deg, #00FFD1, #21C9DC 100%, #ECECEC 0%)",
    turqoiseDark: "linear-gradient(180deg, #00FFD1 -24.23%, #0000FF 128.35%)",
  },
  common: {
    black: "#000",
    white: "#fff",
    turqoise: "#00FFD1",
  },
};

const theme = createTheme({
  palette,
  typography: {
    button: {
      textTransform: "none",
    },
    h1: {
      fontSize: 36,
      fontWeight: 700,
      fontFamily: "Signika",
    },
    h2: {
      fontSize: 28,
      fontWeight: 600,
      fontFamily: "Signika",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      color: palette.secondary.main,
      fontFamily: "Inter",
    },
    h4: {
      fontSize: 14,
      fontWeight: 600,
      color: palette.info.main,
      fontFamily: "Inter",
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
      color: palette.secondary.main,
      fontFamily: "Inter",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      color: palette.secondary.main,
      fontFamily: "Inter",
      lineHeight: "inherit",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 400,
      color: palette.secondary.dark,
      fontFamily: "Inter",
    },
    caption: {
      fontSize: 14,
      fontFamily: "Inter",
      letterSpacing: "normal",
    },
    body2: {
      fontSize: 14,
      color: palette.secondary.main,
      fontFamily: "Inter",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: palette.secondary.main,
          borderRadius: 0,
          padding: "12px 0",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        filled: {
          background: palette.primary.light,
          color: palette.common.white,
        },
        outlined: {
          color: palette.primary.light,
          borderColor: palette.primary.light,
          fontWeight: 600,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        bar1Determinate: {
          background: palette.gradients.dark,
          borderRadius: 40,
        },
        root: {
          background: palette.info.lightest,
          height: 6,
          borderRadius: 40,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
        contained: {
          fontSize: 16,
          fontWeight: 600,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
          fontSize: 12,
        },
      },
    },
  },
});

export default theme;
