import { AcquisitionType, AllowedFileType, BuyerRoles } from "../types";
import { IBuyer, ICountryData, IStartup, IValueItem } from "../models";
import { getFlagEmoji, supportsEmoji } from "../utils";

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const ENV = process.env.REACT_APP_ENV;
export const LANDING_PAGE_LINK = process.env.REACT_APP_LANDING_PAGE_LINK;
export const RESOURCES_PAGE_LINK = process.env
  .REACT_APP_RESOURCES_PAGE_LINK as string;
export const FAQ_PAGE_LINK = process.env.REACT_APP_FAQ_PAGE_LINK as string;
export const LINKEDIN_CONVERSATION_ID = 9548481;
export const IS_PROD = ENV === "prod";
export const IS_TEST = ENV === "test";
export const IS_DEV = ENV === "dev" || !ENV;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
export const ACQUISITION_TYPES: AcquisitionType[] =
  Object.values(AcquisitionType);
export const ALLOWED_FILE_TYPES: AllowedFileType[] =
  Object.values(AllowedFileType);
export const BUYER_ROLES: BuyerRoles[] = Object.values(BuyerRoles);

export const NUMBER_ABBREVIATIONS = ["h", "k", "M", "G", "T", "P", "E", "Z", "Y"];

export const STARTUP_INTRO_KEYS: Array<keyof IStartup> = [
  "contact_first_name",
  "contact_last_name",
  "name",
  "country",
  "city",
  "contact_email",
  "website_url",
  "country_of_incorporation",
  "registering_body",
  "company_registration_number",
  "registering_url",
];

export const BUYER_PUBLIC_KEYS: Array<keyof IBuyer> = [
  "headline",
  "valuation",
  "startup_types",
  "location",
  "acquisition_type",
  "description",
  "role",
  "minimum_revenge_range",
  "maximum_revenge_range",
  "investments_have",
  "investments_projected",
  "acquisitions_projected",
  "keywords",
  "acquisitions_previously",
];

export const LOCATION_CLUSTERS: string[] = [
  "Europe",
  "North_America",
  "Other_Continents",
  "Anywhere",
];

export const SUPPORTS_FLAG_EMOJI = supportsEmoji("🇰🇬");

export const PRESENT_DATE = new Date();

export const MAX_LENGTH = {
  xl: 500,
  lg: 250,
  md: 200,
  sm: 100,
  xs: 25,
};

export const URL_EXP =
  /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;

const COUNTRIES_CODES = [
  "GB",
  "US",
  "DE",
  "FR",
  "AU",
  "CA",
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "EU",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "GA",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "UN",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
];

export const COUNTRIES_LIST: ICountryData[] = COUNTRIES_CODES.map((item) => ({
  emoji: getFlagEmoji(item),
  png: `https://flagcdn.com/h24/${item.toLowerCase()}.png`,
  code: item,
}));

export const CLUSTERS_LIST: ICountryData[] = LOCATION_CLUSTERS.map((item) => ({
  emoji: "🌐",
  code: item,
}));

export const LOCATIONS_LIST: ICountryData[] = [
  ...CLUSTERS_LIST,
  ...COUNTRIES_LIST,
];

export const numberOfCusRec: Record<number, string> = {
  0: "0  to 10",
  11: "11 to 50",
  51: "51 to 100",
  101: "101 to 500",
  501: "501 to 1000",
  1001: "1001 to 5000",
  5001: "5001 to 25000",
  25001: "25001 to 100,000",
  100001: "100,001 to 250,000",
  250001: "250,001 +",
};

export const numberOfCustomer: IValueItem[] = Object.entries(
  numberOfCusRec
).map(([k, v]) => ({ text: v, value: k }));

export const assistanceBookLink =
  "https://calendly.com/joelewin-foundy/20-min-meeting-with-zwings";
