import React from "react";
import { RestrictedPath, RouteType } from "../RestrictedPath";
import { Route, Routes } from "react-router-dom";
import { AppHelmet } from "../../components/AppHelmet/AppHelmet";

export interface AppRoute {
  type?: RouteType;
  path: string;
  target: React.FC<any>;
}

interface AppRoutesProps {
  routes: AppRoute[];
}

export const AppRoutes = ({ routes }: AppRoutesProps) => {
  return (
    <Routes>
      {routes.map(({ path, type, target: Target }) => {
        const TargetWithHelmet = () => (
          <>
            <AppHelmet />
            <Target />
          </>
        );

        return (
          <Route
            key={path}
            path={path}
            element={
              type !== undefined ? (
                <RestrictedPath type={type} target={TargetWithHelmet} />
              ) : (
                <TargetWithHelmet />
              )
            }
          />
        );
      })}
    </Routes>
  );
};
