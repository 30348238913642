import React from "react";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import reportWebVitals from "./common/utils/reportWebVitals";
import theme from "./common/utils/theme";
import "./common/utils/i18n";
import { LocationService } from "./common/services/LocationService";
import "./App.css";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { IS_DEV, IS_PROD, IS_TEST, SENTRY_DSN } from "./common/constants";

Sentry.init({
  enabled: IS_PROD || IS_TEST,
  debug: IS_DEV,
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <HistoryRouter history={LocationService.History}>
        <App />
      </HistoryRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
