import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IUserFull } from "../models";
import { AppService } from "../services/AppService";
import { AppLoader } from "../components/AppLoader";
import { MarketplaceType, UserType } from "../types";
import { FillingPercentsService } from "../services/FillingPercentsService";

type UserUpdater = (
  v: UserPayload | ((prevState: IUserFull) => IUserFull)
) => void;

interface IAppContext {
  user: IUserFull;
  updateUser: UserUpdater;
  isAuthed: boolean;
  usersSelectedRole: UserType;
  marketplaceType: MarketplaceType;
  setUsersSelectedRole: (v: UserType) => void;
  setMarketplaceType: (v: MarketplaceType) => void;
}

const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("hook must be used within a context");
  }

  return context;
};

interface ISignUpProviderProps {
  children: ReactNode;
}

const initialUser: IUserFull = {
  id: 0,
  email: "",
  first_name: "",
  active_role: "",
  buyer: 0,
  seller: 0,
  allow_news_letters: false,
  is_email_verified: false,
  is_staff: false,
};

type UserPayload = IUserFull | undefined;

export const AppProvider = ({ children }: ISignUpProviderProps) => {
  const [user, setUser] = useState<IUserFull>(initialUser);
  const isAuthed = useMemo(() => !!user.id, [user]);
  const [loading, setLoading] = useState<boolean>(true);
  const [usersSelectedRole, setUsersSelectedRole] = useState<UserType>(
    UserType.Buyer
  );
  const [marketplaceType, setMarketplaceType] = useState<MarketplaceType>(
    MarketplaceType.Startup
  );

  const updateUser: UserUpdater = useCallback((v) => {
    if (typeof v !== "object") {
      if (v === undefined) {
        v = initialUser;
      }
    }

    if (typeof v === "function") {
      setUser(v);

      return;
    }

    const completePercent = v.buyerFull
      ? FillingPercentsService.BuyerInfo(v.buyerFull)
      : FillingPercentsService.StartupPrivateInfo(v.startup);

    setUser({ ...v, infoSettled: completePercent === 100 });
  }, []);

  useEffect(() => {
    if (!isAuthed) return;

    updateUser(user);
  }, [user.buyerFull, user.sellerFull, user.startup, isAuthed]);

  useEffect(() => {
    AppService.Init().then((user) => {
      updateUser(user);
      setLoading(false);
    });
  }, [updateUser]);

  if (loading) {
    return <AppLoader />;
  }

  return (
    <AppContext.Provider
      value={{
        user,
        updateUser,
        isAuthed,
        usersSelectedRole,
        setUsersSelectedRole,
        marketplaceType,
        setMarketplaceType,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
