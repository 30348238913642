import React, { Suspense } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import CssBaseline from "@mui/material/CssBaseline";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { hotjar } from "react-hotjar";
import { AppProvider } from "./common/context/AppContext";
import { SnackbarProvider } from "notistack";
import { NotificationService } from "./common/services/NotificationService";
import { AppLoader } from "./common/components/AppLoader";
import { CommonPath } from "./common/types";
import { RouteType } from "./common/containers/RestrictedPath";
import { AppRoute, AppRoutes } from "./common/containers/AppRoutes";
import { ClientPath } from "./client/types";
import NotificationDismissButton from "./common/components/NotificationDismissBtn/NotificationDismissButton";

const queryClient = new QueryClient();

const SignIn = React.lazy(() => import("./common/pages/SignIn"));
const SignUp = React.lazy(() => import("./common/pages/SignUp"));
const Recover = React.lazy(() => import("./common/pages/Recover"));
const Password = React.lazy(() => import("./common/pages/Password"));
const Blocked = React.lazy(() => import("./common/pages/Blocked"));
const Verification = React.lazy(() => import("./common/pages/Verification"));
const Verify = React.lazy(() => import("./common/pages/Verify"));
const Startup = React.lazy(() => import("./client/pages/Startup"));
const Buyer = React.lazy(() => import("./client/pages/Buyer"));

const ClientApp = React.lazy(() => import("./client/ClientApp"));
const AdminApp = React.lazy(() => import("./admin/AdminApp"));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID!,
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!);

hotjar.initialize(
  parseInt(process.env.REACT_APP_HOT_JAR_ID!),
  parseInt(process.env.REACT_APP_HOT_JAR_SV!)
);

const routes: AppRoute[] = [
  {
    path: CommonPath.SignIn,
    type: RouteType.NotAuthed,
    target: SignIn,
  },
  {
    path: CommonPath.SignUp,
    type: RouteType.NotAuthed,
    target: SignUp,
  },
  {
    path: CommonPath.Recover,
    type: RouteType.NotAuthed,
    target: Recover,
  },
  {
    path: CommonPath.Password,
    type: RouteType.NotAuthed,
    target: Password,
  },
  {
    path: CommonPath.Password,
    type: RouteType.NotAuthed,
    target: Password,
  },
  {
    path: CommonPath.Blocked,
    type: RouteType.NotAuthed,
    target: Blocked,
  },
  {
    path: CommonPath.Verification,
    type: RouteType.Authed,
    target: Verification,
  },
  {
    path: CommonPath.Verify,
    target: Verify,
  },
  {
    path: ClientPath.Startup + "/:id/*",
    target: Startup,
  },
  {
    path: ClientPath.Buyer + "/:id/*",
    target: Buyer,
  },
  {
    path: "*",
    type: RouteType.Authed,
    target: ClientApp,
  },
  {
    path: "management/*",
    type: RouteType.Authed,
    target: AdminApp,
  },
];

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CssBaseline />
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        action={(key) => <NotificationDismissButton key={key} />}
        maxSnack={3}
        ref={NotificationService.Ref}
        autoHideDuration={3000}
      >
        <AppProvider>
          <AppRoutes routes={routes} />
        </AppProvider>
      </SnackbarProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback={<AppLoader />}>
      <App />
    </Suspense>
  );
}
