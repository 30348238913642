import { Maybe } from "../types/utils";
import { BoolRecord, ValueObj } from "../types";

export const strToBoolRec = (value: Maybe<string>) => {
  if (!value) return {};
  const keyNames = value.split("\n").filter((v) => v);

  const result: { [key: string]: boolean } = {};
  keyNames.forEach((key: string) => {
    result[key] = true;
  });

  return result;
};

export const strToValueObjArr = (value: Maybe<string>): ValueObj[] => {
  if (!value) return [];
  const keyNames = value.split("\n").filter((v) => v);

  return keyNames.map((item: string) => ({
    value: item,
  }));
};

export const valueObjToStr = (v: ValueObj[]) => {
  return v.map((item: ValueObj) => item.value).join("\n");
};

export const boolRecToStr = (v: BoolRecord) => {
  return Object.keys(v)
    .filter((key) => v[key])
    .join("\n");
};

export const getFillingPercent = (value: object, excludedFields?: string[]) => {
  let length = 0;
  let filledLength = 0;

  Object.entries(value).forEach(([key, value]): any => {
    if (excludedFields?.includes(key)) {
      return;
    }

    const isObject =
      typeof value === "object" && !Array.isArray(value) && value !== null;
    const isBoolean = typeof value === "boolean";

    if (isObject || isBoolean) {
      length++;
      filledLength++;
      return;
    }

    const isArray = Array.isArray(value);
    if (isArray) {
      value.forEach((value) => {
        const isObject = typeof value === "object";
        length++;

        if (isObject && value.value) {
          filledLength++;
        } else if (!isObject && value) {
          filledLength++;
        }
      });
      return;
    }

    length++;
    if (value || value === 0) {
      filledLength++;
    }
  });

  return (filledLength / length) * 100;
};
